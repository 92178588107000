import React from "react";
import moment from "moment";
import "../../App.scss";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function Turneringer({ tournaments }) {
  const sortedTournaments = tournaments.tournaments.sort((a, b) => {
    if (a.date > b.date) return 1;
    if (a.date < b.date) return -1;
    return 0;
  });

  const blitz = sortedTournaments.filter((t) => t.key === "blitz").length;
  const bullet = sortedTournaments.filter((t) => t.key === "bullet").length;

  const dates = sortedTournaments.map((tournament) =>
    moment(tournament.date, "YYYY.MM.DD").format("Do MMMM YYYY")
  );

  const numPlayers = [];

  for (let i = 0; i < sortedTournaments.length; i++) {
    let pointColor = "#7CB5EC";

    numPlayers.push({
      y: sortedTournaments[i].players.length,
      color: pointColor,
    });
  }

  const options = {
    chart: {
      spacingTop: 20,
      backgroundColor: "#272521",
    },
    title: {
      text: "Number of players in the tournaments",
      style: {
        color: "#BABABA",
      },
    },
    tooltip: {
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
        '<td style="padding:0"><b>{point.y}<br></b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    xAxis: {
      categories: dates,
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: "#BABABA",
      },
    },
    series: [
      {
        name: "Number of players",
        data: numPlayers,
      },
    ],
  };

  return (
    <div>
      <h2>Tournaments</h2>
      <p>
        {sortedTournaments.length} tournaments have been played: {blitz} blitz
        and {bullet} bullet.
      </p>
      <div className="antall-spillere-chart">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <table
        id="scoreboard"
        className="scoreboard table striped table-responsive-md"
      >
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Date</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th style={{ textAlign: "center" }}>Number of players</th>
            <th style={{ textAlign: "left" }}>Winner</th>
          </tr>
        </thead>
        {sortedTournaments.map((tournament) => {
          return (
            <tr>
              <td>
                {moment(tournament.date, "YYYY.MM.DD").format(
                  "Do MMMM YYYY",
                  "en"
                )}
              </td>
              <td style={{ textAlign: "left" }}>
                <a
                  href={tournament.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tournament.fullName}
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                {tournament.players.length}
              </td>
              <td style={{ textAlign: "left" }}>{tournament.players[0]}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default Turneringer;

import medal1 from "./icons/medal.png";
import second from "./icons/second.png";
import award from "./icons/award.png";
import third from "./icons/third.png";
import bulletbill from "./icons/bulletbill.png";
import shark from "./icons/shark.png";
import pilopp from "./icons/top-right.png";
import ti from "./icons/10.png";
import tjue from "./icons/20.png";
import bongcloud from "./icons/bongcloud.png";
import tretti from "./icons/30.png";
import femti from "./icons/50.png";
import hundre from "./icons/100.png";
import slingshot from "./icons/slingshot.png";
import boxer from "./icons/boxer.png";
import gm from "./icons/crown.png";
import im from "./icons/quality.png";
import fm from "./icons/radio.png";
import cm from "./icons/cm.png";
import arm from "./icons/robot-arm.png";
import gepard from "./icons/cheetah.png";
import god from "./icons/god.png";
import gg from "./icons/gg.png";
import wingg from "./icons/wingg.png";
import rogue from "./icons/rogue.png";
import fox from "./icons/fox.png";
import soze from "./icons/soze.png";
import promotion from "./icons/promotion.png";
import knightmate from "./icons/knightmate.png";
import bishopknight from "./icons/bishopknight.png";
import rock from "./icons/rock.png";
import point from "./icons/point.png";
import strat from "./icons/strat.png";
import hugh from "./icons/hugh.png";
import tdk from "./icons/dark_knight.png";
import horse from "./icons/horse.png";
import mafia from "./icons/mafioso.png";
import sangria from "./icons/sangria.png";
import queen from "./icons/queen.png";
import dominance from "./icons/dominance.png";
import composer from "./icons/composer.png";
import baby from "./icons/baby.png";
import gettingthere from "./icons/gettingthere.png";
import growing from "./icons/growing.png";
import talking from "./icons/chat.png";
import cashing from "./icons/cashing.png";
import glue from "./icons/glue.png";
import troll from "./icons/troll.png";
import cross from "./icons/cross.png";
import keeper from "./icons/keeper.png";
import gandalf from "./icons/gandalf.png";
import pusheen from "./icons/pusheen.png";
import two from "./icons/two.png";
import three from "./icons/three.png";
import four from "./icons/four.png";
import five from "./icons/five.png";
import stroller from "./icons/stroller.png";
import halloween from "./icons/halloween.png";
import walk from "./icons/walk.png";
import walk_flipped from "./icons/walk_flipped.png";
import qoth from "./icons/qoth.png";
import twins from "./icons/twins.png";
import sword from "./icons/sword.png";
import speed from "./icons/speed.png";
import risk from "./icons/caution.png";
import danger from "./icons/skull.png";
import berserk_win from "./icons/berserk_win.png";
import runner from "./icons/runner.png";
import bigben from "./icons/big-ben.png";
import bird from "./icons/bird.png";
import gun from "./icons/gun.png";
import french from "./icons/french.png";
import spaghetti from "./icons/spaghetti.png";
import newbie from "./icons/newbie.png";
import trump from "./icons/trump.png";
import george from "./icons/george.png";
import castle from "./icons/castle.png";
import short_castle from "./icons/sand-castle.png"
import owen from "./icons/owen.png"
import carr from "./icons/carr.png"
import scandi from "./icons/scandi.png"
import dutch from "./icons/dutch.png"
import triathlon from "./icons/triathlon.png"
import marathon from "./icons/marathon.png"
import seasoned from "./icons/seasoned.png"
import benko from "./icons/benko.png"
import goliath from "./icons/goliath.png"
import giant from "./icons/giant.png"
import miniature from "./icons/miniature.png"
import mini2 from "./icons/mini2.png"
import mini from "./icons/mini.png"
import dancingqueen from "./icons/dancing-queen.png"

export default [
  {
    title: "STARTED",
    image: medal1,
    description: "Play one tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.tournaments?.length;
    },
    xp: 10,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "GOLD",
    image: medal1,
    description: "Win a tournament",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "SILVER",
    image: second,
    description: "Finish within the top two",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces + player.secondPlaces;
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BRONZE",
    image: third,
    description: "Finish within the top third",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.firstPlaces + player.secondPlaces + player.thirdPlaces;
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "MASTER",
    image: award,
    description: "Finish among the top 3 in 5 tournaments",
    secret: false,
    fr_specific: false,
    check: function (player) {
      const pallPlasseringer =
        player.firstPlaces + player.secondPlaces + player.thirdPlaces;
      return pallPlasseringer > 4;
    },
    xp: 2000,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "UNDERDOG",
    image: slingshot,
    description: "Beat a higher rated player",
    secret: false,
    fr_specific: false,
    check: function (player) {
      return player.beaten_higher_rated;
    },
    xp: 50,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "SHARK",
    image: shark,
    description: "Win a Blitz tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.filter(
        (t) => t.key === "blitz" && t.rank === 1
      ).length;
    },
    xp: 1000,
    lvlReq: 5,
    permanen: true,
  },
  {
    title: "BULLET BILL",
    image: bulletbill,
    description: "Win a Bullet tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find(
        (t) => t.key === "bullet" && t.rank === 1
      );
    },
    xp: 1000,
    lvlReq: 5,
    permanen: true,
  },
  {
    title: "PERFORMANCE",
    image: pilopp,
    description: "Perform higher than your rating in a tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.performance - t.rating > 0);
    },
    xp: 250,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "TEN",
    image: ti,
    description: "Get at least 10 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 9);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "TWENTY",
    image: tjue,
    description: "Get at least 20 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 19);
    },
    xp: 20,
    lvlReq: 1,
    permanent: true,
  },
  {
    title: "THIRTY",
    image: tretti,
    description: "Get at least 30 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 29);
    },
    xp: 90,
    lvlReq: 2,
    permanent: true,
  },
  {
    title: "FIFTY",
    image: femti,
    description: "Get at least 50 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 49);
    },
    xp: 300,
    lvlReq: 3,
    permanent: true,
  },
  {
    title: "HUNDRED",
    image: hundre,
    description: "Get at least 100 points in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tournaments?.find((t) => t.score > 99);
    },
    xp: 500,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "TITLEBREAKER",
    image: boxer,
    description: "Beat a titled player.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_titled;
    },
    xp: 800,
    lvlReq: 5,
    permanent: true,
  },
  {
    title: "BERSERK",
    image: sword,
    description: "Berserk a game",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.berserk;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "BERSERK WIN",
    image: berserk_win,
    description: "Berserk a game and win",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.berserk_win;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "USAIN BOLT",
    image: runner,
    description: "Checkmate in 10 seconds or less",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.fastest_checkmate < 11;
    },
    xp: 958,
    lvlReq: 5,
    category: "time",
    permanent: true,
  },
  {
    title: "KING",
    image: gm,
    description: "Beat a GM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_gm;
    },
    xp: 800,
    lvlReq: 7,
    permanent: true,
  },
  {
    title: "PRINCE",
    image: im,
    description: "Beat an IM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_im;
    },
    lvlReq: 7,
    xp: 600,
    permanent: true,
  },
  {
    title: "DAB",
    image: fm,
    description: "Beat an FM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_fm;
    },
    lvlReq: 5,
    xp: 350,
    permanent: true,
  },
  {
    title: "CM BEATER",
    image: cm,
    description: "Beat a CM",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_cm;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "WOW",
    image: arm,
    description: "Beat a player rated at least 300 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_300_higher_rated;
    },
    lvlReq: 5,
    xp: 300,
    permanent: true,
  },
  {
    title: "GIANT KILLER",
    image: giant,
    description: "Beat a player rated at least 500 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_500_higher_rated;
    },
    lvlReq: 5,
    xp: 800,
    permanent: true,
  },
  {
    title: "Goliath Slayer",
    image: goliath,
    description: "Beat a player rated at least 800 points higher than you",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.beaten_800_higher_rated;
    },
    lvlReq: 5,
    xp: 1500,
    permanent: true,
  },
  {
    title: "CHEETAH",
    image: gepard,
    description: "Play 40+ games in one tournament",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.flest_partier_paa_en_turnering > 39;
    },
    lvlReq: 5,
    xp: 1000,
    permanent: true,
  },
  {
    title: "PLAYER",
    image: rogue,
    description: "Play 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 99;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "FOX",
    image: fox,
    description: "Play 300 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.totalt_partier > 299;
    },
    lvlReq: 5,
    xp: 800,
    permanent: true,
  },
  {
    title: "KNIGHTMARE",
    image: knightmate,
    description: "Promote to knight with mate",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.wowowK;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "TEAMWORK",
    image: bishopknight,
    description: "Mate with bishop and knight",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.mate_with_bishop_and_knight;
    },
    lvlReq: 7,
    xp: 1200,
    permanent: true,
  },
  {
    title: "TO THE POINT",
    image: point,
    description: "Play 1. e4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "STRATEGIST",
    image: strat,
    description: "Play 1. d4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_d4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "THE GUN",
    image: gun,
    description: "Play Alekhine's Defense",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf6;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "THE BIRDS",
    image: bird,
    description: "Win with the Bird (1. f4)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_f4;
    },
    lvlReq: 2,
    xp: 100,
    permanent: false,
  },
  {
    title: "THE SHORTCUT",
    image: french,
    description: "Play the French",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_e6;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "HUGH GRANT",
    image: hugh,
    description: "Play 1. c4",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c4;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "THE HORSE WHISPERER",
    image: horse,
    description: "Play 1. Nf3",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_nf3;
    },
    lvlReq: 1,
    xp: 20,
    permanent: true,
  },
  {
    title: "SANGRIA",
    image: sangria,
    description: "Play the Spanish",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_spanish;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "MOM'S SPAGHETTI",
    image: spaghetti,
    description: "Play the Italian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_italian;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: 'THE "GAMBIT"',
    image: queen,
    description: "Play the Queen's Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_qg;
    },
    lvlReq: 2,
    xp: 50,
    permanent: true,
  },
  {
    title: "BABY STEPS",
    image: baby,
    description: "Win a game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 0;
    },
    lvlReq: 1,
    xp: 30,
    permanent: true,
  },
  {
    title: "GROWING",
    image: growing,
    description: "Win 10 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 9;
    },
    lvlReq: 2,
    xp: 70,
    permanent: true,
  },
  {
    title: "GETTING THERE",
    image: gettingthere,
    description: "Win 30 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 29;
    },
    lvlReq: 3,
    xp: 300,
    permanent: true,
  },
  {
    title: "NOW WE'RE TALKING",
    image: talking,
    description: "Win 100 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 99;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "CASHING IN",
    image: cashing,
    description: "Win 250 games",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.total_won_games > 249;
    },
    lvlReq: 7,
    xp: 1000,
    permanent: true,
  },
  {
    title: "GOOD LUCK",
    image: cross,
    description: "Play Magnus (DrNykterstein)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_magnus;
    },
    lvlReq: 1,
    xp: 80,
    permanent: true,
  },
  {
    title: "MEOW",
    image: pusheen,
    description: "Play PusheenMeow",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.played_pusheen;
    },
    lvlReq: 1,
    xp: 50,
    permanent: true,
  },
  {
    title: "KEEPER",
    image: keeper,
    description: "Keep at least 13 pieces (and win)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken < 4;
    },
    lvlReq: 3,
    xp: 250,
    permanent: true,
  },
  {
    title: "STREAK",
    image: two,
    description: "Win 2 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 1;
    },
    lvlReq: 2,
    xp: 60,
    permanent: true,
  },
  {
    title: "STREEAK",
    image: three,
    description: "Win 3 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 2;
    },
    lvlReq: 3,
    xp: 200,
    permanent: true,
  },
  {
    title: "STREEEAK",
    image: four,
    description: "Win 4 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 3;
    },
    lvlReq: 5,
    xp: 500,
    permanent: true,
  },
  {
    title: "STREEEEAK",
    image: five,
    description: "Win 5 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 4;
    },
    lvlReq: 7,
    xp: 800,
    permanent: true,
  },
  {
    title: "ADOPTION",
    image: stroller,
    description: "Win 10 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 9;
    },
    lvlReq: 10,
    xp: 1000,
    permanent: true,
  },
  {
    title: "TWINS",
    image: twins,
    description: "Win 20 games in a row",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_seire_paa_rad > 19;
    },
    lvlReq: 10,
    xp: 2000,
    permanent: true,
  },
  {
    title: "RISKY",
    image: risk,
    description: "5 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premove_streak > 4;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "BIG BEN",
    image: bigben,
    description: "Play the London",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_london;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "TRUMP",
    image: trump,
    description: "Play the trompowsky",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_trompowsky;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "ROCK OF GIBRALTAR",
    image: rock,
    description: "Play the Caro-Kann",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c6;
    },
    lvlReq: 1,
    xp: 40,
    permanent: false,
  },
  {
    title: "COMPOSER",
    image: composer,
    description: "Play the Philidor Defence",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_philidor;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "LEFONG",
    image: troll,
    description: "Win after a succeeded Lefong",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.lefong;
    },
    lvlReq: 7,
    xp: 800,
    permanent: false,
    jackpot: true
  },
  {
    title: "THE DARK KNIGHT",
    image: tdk,
    description: "Move a black knight the first 4 moves (and win).",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.tdk;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
  {
    title: "MAFIA",
    image: mafia,
    description: "Play the Sicilian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_c5;
    },
    lvlReq: 2,
    xp: 50,
    permanent: false,
  },
  {
    title: "SPEED 2",
    image: speed,
    description:
      "Spend less than one second per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 1;
    },
    xp: 300,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "QUEEN OF THE HILL",
    image: qoth,
    description:
      "Reach one of the 4 center squares with your queen within move 3 (and win).",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.qoth;
    },
    xp: 250,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "DANGER",
    image: danger,
    description: "10 premoves in a row",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.longest_premove_streak > 9;
    },
    xp: 600,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "GG",
    image: gg,
    description: "Play 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_g4;
    },
    xp: 80,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "Newbie",
    image: newbie,
    description: "Play the newbie with white (1. a4 2. Ra3)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_newbie_white;
    },
    xp: 90,
    lvlReq: 1,
    permanent: false,
  },
  {
    title: "SPEED",
    image: speed,
    description:
      "Spend less than 2 seconds per move on average (in a won game lasting at least 20 moves)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.best_seconds_per_move < 2;
    },
    xp: 180,
    lvlReq: 5,
    category: "time",
    permanent: false,
  },
  {
    title: "GOLDEN GG",
    image: wingg,
    description: "Win with 1. g4",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_g4;
    },
    xp: 150,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "GLUE",
    image: glue,
    description: "Play more than 60 moves in one game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_trekk_parti > 60;
    },
    lvlReq: 3,
    xp: 200,
    permanent: false,
  },
  {
    title: "THERE...",
    image: walk,
    description: "Reach the other side of the board with your King (and win)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.king_reached_last_row;
    },
    xp: 310,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "...AND BACK AGAIN",
    image: walk_flipped,
    description:
      "Reach the starting rank after reaching the other side of the board with your King (and win)",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.king_reached_first_row;
    },
    xp: 340,
    lvlReq: 5,
    permanent: false,
  },
  {
    title: "PROMOTION",
    image: promotion,
    description: "Promote at least 4 pawns during one game",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_number_of_promotions > 3;
    },
    lvlReq: 5,
    xp: 700,
    permanent: false,
  },
  {
    title: "KEYSER SÖZE",
    image: soze,
    description: "Capture all of your opponent's pieces",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.max_antall_brikker_slaatt === 15;
    },
    lvlReq: 5,
    xp: 700,
    permanent: false,
  },
  {
    title: "HALLOWEEN",
    image: halloween,
    description: "Win with the Halloween Gambit",
    secret: false,
    enabled: true,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_halloween;
    },
    xp: 350,
    permanent: false,
  },
  {
    title: "DOMINANCE",
    image: dominance,
    description: "Win without losing a single piece",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_own_pieces_taken === 0;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
  {
    title: "GREEK GOD",
    image: god,
    description: "Win with The Greek (1. f3 2. Kf2)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_greek;
    },
    lvlReq: 7,
    xp: 300,
    permanent: false,
  },
  {
    title: "BONGCLOUD",
    image: bongcloud,
    description: "Win with the Bongcloud (1. e4 2. Ke2)",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_bongcloud;
    },
    lvlReq: 7,
    xp: 340,
    permanent: false,
  },
  {
    title: "ST. GEORGE",
    image: george,
    description: "Win with 1... a6",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_a6;
    },
    lvlReq: 1,
    xp: 150,
    permanent: false,
    new: true,
  },
  {
    title: "MICHAEL OWEN",
    image: owen,
    description: "Win with 1... b6",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_b6;
    },
    lvlReq: 1,
    xp: 150,
    permanent: false,
    new: true,
  },
  {
    title: "JIM CARREY",
    image: carr,
    description: "Win with 1... h6",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.win_with_h6;
    },
    lvlReq: 1,
    xp: 150,
    permanent: false,
    new: true,
  },
  {
    title: "SCANDINAVIAN",
    image: scandi,
    description: "Play the Scandinavian",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_scandi;
    },
    lvlReq: 1,
    xp: 50,
    permanent: false,
    new: true,
  },
  {
    title: "DUTCH",
    image: dutch,
    description: "Play the dutch",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_dutch;
    },
    lvlReq: 1,
    xp: 50,
    permanent: false,
    new: true,
  },
  {
    title: "BENKO",
    image: benko,
    description: "Play the Benko Gambit",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.play_benko;
    },
    lvlReq: 1,
    xp: 50,
    permanent: false,
    new: true,
  },
  {
    title: "FAST CASTLE",
    image: short_castle,
    description: "Castle kingside on move 4 and win",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.short_castle_4_and_won;
    },
    lvlReq: 1,
    xp: 260,
    permanent: false,
    new: true,
  },
  {
    title: "FAST CASTLE 2",
    image: castle,
    description: "Castle queenside on move 5 and win",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.long_castle_5_and_won;
    },
    lvlReq: 1,
    xp: 290,
    permanent: false,
    new: true,
  },
  {
    title: "TRIATHLETE",
    image: triathlon,
    description: "Play a least 3 tournaments in a season",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.num_tournaments >= 3;
    },
    lvlReq: 1,
    xp: 500,
    permanent: false,
    new: true,
  },
  {
    title: "MARATHONER",
    image: marathon,
    description: "Play a least 6 tournaments in a season",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.num_tournaments >= 6;
    },
    lvlReq: 1,
    xp: 1000,
    permanent: false,
    new: true,
  },
  {
    title: "SEASONED",
    image: seasoned,
    description: "Play ALL tournaments in a season",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.num_tournaments >= 10; // Denne må endres før hver turnering
    },
    lvlReq: 1,
    xp: 2000,
    permanent: false,
    new: true,
  },
  {
    title: "MINIATURE",
    image: miniature,
    description: "Win in 20 moves or less",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_trekk_parti_seier <= 20;
    },
    lvlReq: 1,
    xp: 300,
    permanent: false,
    new: true,
  },
  {
    title: "BRIEF BRILLIANCE",
    image: mini2,
    description: "Win in 15 moves or less",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_trekk_parti_seier <= 15;
    },
    lvlReq: 1,
    xp: 800,
    permanent: false,
    new: true,
  },
  {
    title: "MINI JACOBSEN",
    image: mini,
    description: "Win in 10 moves or less",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.min_trekk_parti_seier <= 10;
    },
    lvlReq: 1,
    xp: 1200,
    permanent: false,
    new: true,
  },
  {
    title: "DANCING QUEEN",
    image: dancingqueen,
    description: "Move the queen 9 times during the first 10 moves, and win.",
    secret: false,
    fr_specific: false,
    check: function (player, alle_turneringer, cumScores) {
      return player.dancing_queen;
    },
    lvlReq: 1,
    xp: 1000,
    permanent: false,
    new: true,
  },
];
